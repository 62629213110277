const Error404 = () => {
    return (
        <div className="error-wrapper">
            <div>
                <img src="" alt="" />
                <h1>404 Error</h1>
            </div>
            <h2>Page Not Found</h2>
        </div>
    )
}

export default Error404