import React from 'react';
import ArticleDetail from '../ArticleDetail';

const EventDetail = () => {
    return (
        <div>
            <ArticleDetail type="Event"></ArticleDetail>
        </div>
    );
};

export default EventDetail;